<template>
  <CContainer class="text-black">
    <hr class="mt-0" />
    <div style="overflow-x: auto" v-if="categories.length > 0">
      <table aria-describedby="">
        <tr>
          <td
            style="padding: 2px"
            v-for="category in categories"
            :key="category.objectId"
            @click="goToByCategoryLists(category.objectId, category.name)"
          >
            <div
              class="circle shadow-menu"
              :style="{
                'background-color': getCategoryColor(category.indexColor),
              }"
            >
              <h3>{{ catIcon(category.name) }}</h3>
            </div>
            <h6 class="text-center" style="font-size: 10px">
              {{ category.name }}
            </h6>
          </td>
        </tr>
      </table>
    </div>

    <grid-menu
      class="mt-1"
      v-bind:cartTotal="cartTotal"
      v-bind:display="display"
      @update-cartTotal="updateCartTotal"
    ></grid-menu>
    <br />

    <footer class="fixed-bottom mb-6">
      <router-link to="/pickup/mycart" class="text-black">
        <div class="notification-badges">
          <div class="cartbtn">
            <p :data-badge="cartTotal" v-if="cartTotal > 0">
              <CIcon name="cil-cart" size="xl"></CIcon>
            </p>
            <p v-else>
              <CIcon name="cil-cart" size="xl"></CIcon>
            </p>
          </div>
        </div>
      </router-link>
    </footer>
  </CContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import pos from '@/services/pos'
import util from '@/util/util'
import CategoryLists from '../menu/CategoryLists'
import GridMenu from '../menu/GridMenu'
import '@/util/menu.css'

export default {
  components: {
    CategoryLists,
    GridMenu,
  },
  data() {
    return {
      products: [],
      categories: [],
      totally: 0,
      cartLists: [],
      cartTotal: 0,
      display: 'grid',
    }
  },
  computed: {
    ...mapGetters(['shopLineOA', 'shopLineObjectId']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shopLineObjectId: {
      get() {
        return this.$store.getters.shopLineObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShopLineOA', newValue)
      },
    },
    shopName() {
      if (this.shopLineOA.length > 0) {
        return this.shopLineOA[0].shopName
      } else {
        return ''
      }
    },
    logo() {
      if (this.shopLineOA.length > 0) {
        return this.shopLineOA[0].remoteImagePath
      } else {
        return ''
      }
    },
  },
  created() {
    this.getProduct()
    this.getCategory()
    this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || []
    this.getCartTotal()
  },
  methods: {
    ...util,
    updateCartTotal(total) {
      this.cartTotal = total
    },
    getCartTotal() {
      let total = this.cartLists.reduce((accum, product) => {
        return accum + product.quantity
      }, 0)

      this.cartTotal = total
    },
    catIcon(catName) {
      return catName.charAt(0).toUpperCase()
    },
    getCategoryColor(indexColor) {
      return util.generateColor(indexColor)
    },
    shopHandle(objectId) {
      this.shopLineObjectId = objectId
      this.cartTotal = 0
    },
    goToByCategoryLists(objectId, name) {
      sessionStorage.setItem('categoryName', name)
      this.$router.push('/pickup/' + objectId + '/category')
    },
    getProduct(page = 1) {
      const uid = this.uid

      let params = {
        shopObjectId: this.shopLineObjectId,
        page: page,
        limit: 100,
      }

      pos
        .get('/api/v1.0/' + uid + '/Shop/getproduct/data', { params })
        .then((res) => {
          this.products = res.data.data
          this.totally = this.products.length
        })
    },
    getCategory() {
      const uid = this.uid
      let shopObjectId = this.shopLineObjectId

      pos
        .get('/api/v1.0/shop/' + uid + '/Category/menu/' + shopObjectId)
        .then((res) => {
          this.categories = res.data.data
        })
    },
  },
}
</script>
